import logo1 from '../Images/solibra.png';
import logo2 from '../Images/canalplus.png';
import logo3 from '../Images/boa.png';
import logo4 from '../Images/douane.jpeg';
import logo5 from '../Images/rti.jpeg';
import logo6 from '../Images/smartenergie.jpeg';


export const partners =
  [
    { name: "Solibra", logo: logo1 },
    { name: "canalplus", logo: logo2 },
    { name: "boa", logo: logo3 },
    { name: "douaneivoirienne", logo: logo4 },
    { name: "rti", logo: logo5 },
    { name: "smartenergie", logo: logo6 }
  ];
