import React from 'react';
import banner from "../../Images/banner-two.jpg";
import '../banners/Banner.css';

const Banner = () => {
  return (
    <div className='mediaHeader' style={{ backgroundImage: `url(${banner})` }}>



            <h1 className="title-banner">Bienvenue sur African Project Management Company <br /></h1>
            <p>Align​ons nos Capacités Pro​​fessionnelles aux standards Internationaux dans le domaine du management de projet</p>
            <div className='banner-actions'>
                <a className="btn" href="/apropos">En savoir plus</a>
                <a className="btn" href="/contact">Contactez-nous</a>
            </div>



    </div>
  );
}

export default Banner;
