import React from 'react';
import HomePage from './PagesContent/HomePage';
import  "../src/App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,

} from "react-router-dom";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AboutPage from './PagesContent/AboutPage';
import Contact from './PagesContent/Contact';
import Consulting from './PagesContent/Consulting';
import Formation from './PagesContent/Formation';

const App = () => {
  return (
    <>
      <Router>
      < Header/>
        <Routes>
          <Route path= '/' exact Component={HomePage}/>
          <Route path='apropos' exact Component={AboutPage} />
          <Route path='contact' exact Component={Contact} />
          <Route path='consulting' exact Component={Consulting} />
          <Route path='formation' exact Component={Formation} />
        </Routes>
        < Footer />
      </Router>
    </>
  );
}

export default App;
