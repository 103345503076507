import React, { useRef, useState } from 'react';
import '../PagesContent/Contact.css';
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';

const Contact = () => {
    const form = useRef();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
      e.preventDefault();
      console.log(sendEmail);
      console.log("Nom :", name);
      console.log("Téléphone :", phone);
      console.log("Email :", email);
      console.log("Sujet :", subject);
      console.log("Message :", message);
      emailjs.sendForm(
        "service_l7kh66e",
        "template_uflnlat",
        form.current,
        "N09iOhIRm499SIfIm"
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result);
          Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Votre message a été envoyé avec succès! Vous serez contacté par un coach.',
          });
        },
        (error) => {
          console.error('Error sending email:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erreur lors de l/envoi!',
            footer: ''
          })
        }
      );
      e.target.reset();
      setName('');
      setPhone('');
      setEmail('');
      setSubject('');
      setMessage('');
    }
  return (
    <div >
      <section>
          <h4 className='sectionHeader'>Contactez-nous</h4>
          <h1 className='heading'>Get in touch</h1>
        <p className='para'>Nous sommes impatients de vous entendre ! Notre équipe est là pour répondre à toutes vos qestions et vous offrir le meilleur service possible. Remplissez seulement le formulaire de contact ci-dessous ! </p>
          <div className="contactForm">
            <form ref={form} onSubmit={sendEmail} action="">
              <h1 className="sub-heading">Besoin d'informations !</h1>
              <p className='para para2'>
                Contactez nous pour booster vos projets
              </p>
              <input type="text" className='input' id='name' name='name' value={name} onChange={(e) => setName(e.target.value)} placeholder='Votre nom et prénom' />
              <input type="text" className='input' id='phone' name='phone' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Votre téléphone' />
              <input type="text" className='input' id='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Votre email' />
              <input type="text" className='input' id='subject' name='subject' value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Votre sujet' />
              <textarea className='input' id='message' name='message' value={message} onChange={(e) => setMessage(e.target.value)}  cols="30" rows="5"></textarea>
              <input type="submit" className='btn' value="Envoyer Message" />
            </form>
            <div className="map-container">
              <div className="mapBg"></div>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.3244244223033!2d-3.98349705911055!3d5.3673899946339025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1ed6b5b196e79%3A0xb80cb50e5687b893!2sRIVIERA%20ATTOBAN!5e0!3m2!1sfr!2sci!4v1710345074512!5m2!1sfr!2sci"
                  width="800"
                  height="600"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="contactMethod">
            <div className="method">
              <i class="fa-solid fa-location-dot contactIcon"></i>
              <article className="text">
                <h1 className="sub-heading">Adresse</h1>
                <p className='para'>Riviéra Attoban, Cocody Abidjan</p>
              </article>
            </div>

            <div className="method">
              <i class="fa-solid fa-envelope contactIcon"></i>
              <article className="text">
                <h1 className="sub-heading">E-mail</h1>
                <p className='para'>contact@africanpmc.com</p>
              </article>
            </div>

            <div className="method">
              <i class="fa-solid fa-phone contactIcon"></i>
              <article className="text">
                <h1 className="sub-heading">Phone</h1>
                <p className='para'>+225 0767455576</p>
              </article>
            </div>
          </div>

      </section>
    </div>
  );

}


export default Contact;
