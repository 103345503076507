import React from 'react';
import Banner from '../components/banners/Banner';
import data from "../components/Service/data.js";
import Card from '../components/Service/Card.js';
import "../../src/App.css";
import Objectif from '../components/objectifs/Objectif.jsx';
import Cible from '../components/cibles/Cible.jsx';
import PartnersCarousel from '../components/clients/partenersCarousel.jsx';

const HomePage = () => {
  const card = data.map((item) => {
    return  <Card image= {item.img} name={item.name} des={item.des}/>
  })
  return (
    <>
    < Banner/>
    <h1 className="heading">Nos Formations</h1>
    <div className="heading_underline"></div>
      <div className="card_body">
    <div className="wrapper">{card}</div>
      </div>
      < Objectif/>
      < Cible />
      < PartnersCarousel />
    </>
  );
}

export default HomePage;
