import img1 from "../Images/pmp-2.png";
import img2 from "../Images/capm-2.png";
import img3 from "../Images/psm-2.png";
import img4 from "../Images/pspo-2.png";
import img5 from "../Images/pmi-2.png";
import img6 from "../Images/noncertifiant-2.jpg";
export const FormationServices = [
  {
    image: img1,
    title: "PMP® Project Management Professional",
    description:
      "Une certification internationale Américaine à grande valeur ajoutée en gestion de projet, 4ème dans le classement des plus prestigieuses certifications dans le monde, la certification PMP confère à son détenteur une reconnaissance internationale de sa maîtrise en gestion de projet. Le PMP® est une certification décernée par le PMI Project Management Institute), fondée en 1969, une association professionnelle à but non lucratif qui propose des méthodes de Gestion de projet. Son siège est à Philadelphie en Pennsylvanie (États-Unis), elle compte d'après son site internet plus de 700 000 membres répartis dans plus de 200 pays (chiffres 2022).Elle élabore et publie des standards relatifs à la gestion de projet et propose différentes certifications dans ce domaine. N.B : Guide PMBOK 6è & 7è Edition offerts en version numérique",
    pourquoi:
      "L'alignement à des standards internationaux et l'opportunité d'améliorer son savoir-faire en gestion de projet. Le PMP® vous ouvre des portes dans toutes les industries et vous connecte à une communauté d’organisations et d’experts émérites dans le monde entier. Est éligible, toute personne possédante au moins 3 années d'expériences professionnelles de préférence dans les projets avec un diplôme universitaire.",
    prixFormation: "Prix de la formation (HT): 950.000 F CFA",
    fraisExamen: "Frais d’examen (HT) : 350.000 F CFA"
  },
  {
    image: img2,
    title: "CAPM® Certified Associated in Project Management",
    description:
      "Cette certification indique que la personne possède les connaissances des principes et de la terminologie du Guide du référentiel de connaissances en gestion de projet (Guide PMBOK®), qui décrit les bonnes pratiques généralement reconnues en matière de gestion de projet. La certification CAPM®est décernée par le PMI (Project management institute).",
    pourquoi:
      "Les personnes qui portent le titre CAPM® après leur nom bénéficient d’un haut niveau de crédibilité de la part des chefs de projet ayant la qualification PMP®, ainsi que des employeurs et des pairs. Sont éligibles,les personnes détentrices du BAC n’ayant pas d’expérience professionnelle dans la gestion de projet mais qui sont intéressées par la gestion de projet peuvent bénéficier de cette certification. Les personnes qui apportent des compétences spécialisées à une équipe de projet peuvent également bénéficier de cette certification en leur permettant d’aligner leur travail sur celui des chefs de projet. ",
    prixFormation: "Prix de la formation (HT): 750.000 F CFA",
    fraisExamen: "Frais d’examen (HT) : 250.000 F CFA"
  },
  {
    image: img3,
    title: "PSM I Professional Scrum Master I",
    description:
      "La certification Professional Scrum Master (PSM I) valide votre connaissance du cadre Scrum, des responsabilités du Scrum Master et de la manière d'appliquer Scrum. PSM I est reconnu par l'industrie comme une certification qui démontre un niveau fondamental de maîtrise de Scrum. En tant que titulaire de la certification PSM I, vous avez prouvé que vous comprenez Scrum tel que décrit dans le Guide Scrum et comment appliquer Scrum dans les équipes Scrum. Les titulaires de PSM I ont une terminologie et une approche cohérentes de Scrum. La certification PSM I est décernée par Scrum.Org (la maison du Scrum). Scrum.org, la maison de Scrum, a été fondée en 2009 par le co-créateur de Scrum Ken Schwaber en tant qu'organisation basée sur une mission pour aider les personnes et les équipes à résoudre des problèmes complexes. Ils le font en permettant aux gens d'appliquer Professional Scrum par le biais de formations, de certifications et d'apprentissages continus, tous basés sur un modèle de compétences commun. N.B : Guide Scrum offert en version numérique,",
    pourquoi:
      "La certification PSM I aide à acquérir une meilleure compréhension de l'approche agile et de ses pratiques. D'ailleurs, cela permet aux professionnels de mieux appliquer la méthode dans leur travail. Par conséquent, ils peuvent aider leur équipe à être plus productive. Il n’y a pas de prérequis pour passer la certification PSM I. Toute personne guidée par la passion du Scrum peut passer cette certification.",
    prixFormation: "Prix de la formation (HT): 500.000 F CFA",
    fraisExamen: "Frais d’examen (HT) : 150.000 F CFA"
  },
  {
    image: img4,
    title: "PSPO I Professional Scrum Product Owner I",
    description:
      "La certification Professional Scrum Product Owner (PSPO I) valide votre connaissance du cadre Scrum et votre capacité à soutenir et permettre la création et la livraison de valeur. Obtenir le PSPO I montre votre compréhension de la façon de combler le fossé entre la stratégie et la gestion de produit agile afin de créer des produits de valeur à l'aide de Scrum. La certification PSPO I est décernée par Scrum.Org (la maison du Scrum). N.B : Guide Scrum offert en version numérique",
    pourquoi:
      "La certification PSPO I aide à acquérir une meilleure compréhension de l'approche agile et de ses pratiques. D'ailleurs, cela permet aux professionnels de mieux appliquer la méthode dans leur travail. Par conséquent, ils peuvent aider leur équipe à être plus productive. Il n’y a pas de prérequis pour passer la certification PSPO I. Toute personne guidée par la passion du Scrum peut passer cette certification.",
    prixFormation: "Prix de la formation (HT): 500.000 F CFA",
    fraisExamen: "Frais d’examen (HT) : 150.000 F CFA"
  },
  {
    image: img5,
    title: "PMI – RMP ® Project management institut – Risque Management Professional",
    description:
      "Les risques présentent des défis. Non gérés, ils peuvent entraîner des retards, des dépenses, des objectifs manqués et même l'échec du projet. Mais bien gérés, ils peuvent offrir un avantage concurrentiel et même des opportunités. La gestion des risques est un aspect essentiel de toutes les activités organisationnelles. Validez vos compétences avec la certification PMI-RMP et devenez le spécialiste des risques dont votre organisation a besoin. La certification PMI-RMP est décernée par le PMI (Project management institute). N.B: Guide PMBOK 6è & 7è Edition et le The Standard for Risk Management in Portfolios, Programs, and Projects offerts en version numérique",
    pourquoi:
      "La certification PMI-RMP vous positionne pour : Identifier les problèmes avant qu'ils ne surviennent, évaluer les risques du projet, atténuer les menaces, maximiser les résultats et respecter les délais, exploiter, et même créer des opportunités, économiser des ressources pour votre projet et votre organisation. Toute personne possédant au moins 2 années d'expériences professionnelles de préférence dans la gestion des risques de projet avec un diplôme universitaire.  ",
    prixFormation: " Prix de la formation (HT): 950.000 F CFA",
    fraisExamen: "Frais d’examen (HT) : 450.000 F CFA"
  },
  {
    image: img6,
    title: "Formations non certifiantes avec attestations de formation",
    description:
      "Les formations non certifiantes concernent la gestion de projet, la gestion des changements, la gestion des risques, la gouvernance des projets et programmes, et la sensibilation à l'Agilité. N.B : Guide PMBOK 6è & 7è Edition offerts en version numérique",
    pourquoi:
      " Est éligible, toute personne désireuse d'apprendre à conduire un projet, le planifier, définir des objectifs et les atteindre, de mener les nouveaux projets à la réussite, la conduite du changement, l’accompagnement des transformations, d’être sensibilisée et outillée pour assurer une meilleure gestion des risques en projet. Toute personne désireuse d’être sensibilisée et outillée pour assurer une meilleure gouvernance des projets et programmes, pour assurer une bonne gestion des projets agiles.",
    prixFormation: " Prix de la formation (HT): Contactez-nous",
    fraisExamen: "Aucun examen disponible"
  },
]
