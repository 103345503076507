import React from 'react';
import "../../../src/App.css"

const Card = (props) => {
  return (

    <div className='card'>
    <img src={props.image} alt="" />
    <h3>{props.name}</h3>
    <p>{props.des}</p>
    <a href="/contact" className='btn'>
      En savoir plus
    </a>
    </div>

  );
}

export default Card;
