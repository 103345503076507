import img1 from "../../Images/pmp-2.png";
import img2 from "../../Images/capm-2.png";
import img3 from "../../Images/psm-2.png";
import img4 from "../../Images/pspo-2.png";
import img5 from "../../Images/pmi-2.png";
import img6 from  "../../Images/noncertifiant-2.jpg";


export default [
  {
    img: img1,
    name: "PMP® Project Management Professional",
    des: <p>
      Le PMP® vous ouvre des portes dans toutes les industries et vous connecte à une communauté d’organisations et d’experts émérites dans le monde entier.
    </p>
  },

  {
    img: img2,
    name: "CAPM® Certified Associated in Project Management",
    des: <p>
    Le titre CAPM® après leur nom bénéficient d’un haut niveau de crédibilité de la part des chefs de projet ayant la qualification PMP®, ainsi que des employeurs et des pairs.</p>,
  },

  {
    img: img3,
    name: "PSM I: (Professional Scrum Master",
    des: <p>
      La certification PSM I aide à acquérir une meilleure compréhension de l'approche agile et de ses pratiques.</p>,
  },

  {
    img: img4,
    name: "PSPO I Professional Scrum Product Owner I",
    des: <p>
      La certification PSPO I aide à acquérir une meilleure compréhension de l'approche agile et de ses pratiques.</p>,
  },

  {
    img: img5,
    name: "certification PMI – RMP",
    des: <p>
      La certification PMI-RMP vous permet d'identifier les problèmes avant qu'ils ne surviennent, évaluer les risques et atténuer les menaces</p>,
  },

  {
    img: img6,
    name: "Formations non certifiantes",
    des: <p>

      Les formations non certifiantes concernent la gestion de projet, la gestion des changements, la gestion des risques, la gouvernance des projets et programmes, et la sensibilisation à l'Agilité.</p>,
  }
]
