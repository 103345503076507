import React, { useState } from 'react';
import "../PagesContent/Consulting.css";

import { FormationServices } from "../Exports/dataFormation.js";

const Formation = () => {
  console.log(FormationServices);

  const [currentPage, setCurrentPage] = useState(0);

  const displayData = FormationServices[currentPage];

  const goToNextPage = () => {
    if (currentPage < FormationServices.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="consultingConteneur">
      <div className="consultingHeader">
        <p>

        </p>
      </div>
      <div className="consultingBlockService">
        <div key={currentPage} className="consultingServiceContenu">
          <div>
            <img
              src={displayData.image}
              style={{ width: "100%", height: "100%", borderRadius: "20px" }}
              alt="imageDescriptif"
            />
          </div>
          <div>
            <h2 className='heading'>{displayData.title}</h2>
            <h2 style={{ color: "black", paddingBottom:"20px" }}>Ce que vous devez savoir:</h2>
            <p style={{ fontSize: "18px" }}>{displayData.description}</p>
            <h2 style={{ color: "black", padding: "20px" }}>Pourquoi vous devez faire cette formation</h2>
            <p style={{ fontSize: "18px" }}>{displayData.pourquoi}</p>
            <p className='prix-formation' style={{ fontSize: "18px", padding: "20px" }}>{displayData.prixFormation}</p>
            <p className='prix-examen' style={{ fontSize: "18px" }}>{displayData.fraisExamen}</p>
            <br></br>
            <a className="btn" href="/contact">Contactez-nous</a>
          </div>
        </div>
        <div className="pagination">
          <div>
            {currentPage > 0 && (
              <button
                onClick={goToPreviousPage}
                style={{
                  border: "1px solid transparent",
                  fontWeight: "500",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  backgroundColor: "antiquewhite",
                }}
              >
                précédent
              </button>
            )}
          </div>

          <div>
            {currentPage < FormationServices.length - 1 && (
              <button
                onClick={goToNextPage}
                style={{
                  border: "1px solid transparent",
                  fontWeight: "500",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  backgroundColor: "antiquewhite",
                }}
              >
                suivant
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Formation;
