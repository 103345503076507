import React, {useState} from 'react';
import logo from  "../../Images/logo-four.png";
import '../../../src/App.css';
import {
  Link
} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import WorkIcon from '@mui/icons-material/Work';
import GridViewIcon from '@mui/icons-material/GridView';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const [sidebar, setSidebar] =  useState(false);

  window.addEventListener("scroll", function() {
    const header = document.querySelector(".header")
    header.classList.toggle("active", window.screen > 200)
  })
  return (
    <>
      <header className='header'>
        <div className='container flex'>
          <div className='logo'>
            <a href="/">
              <img src={logo} alt="APMC LOGO" style={{ height: '170px', width: '170px', marginTop: '-60px' }} />
            </a>
          </div>

          <div className='nav'>
            <ul className={sidebar ? "nav-links-sidebar" : "nav-links"} onClick={() => setSidebar(false)}>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/apropos">A PROPOS</Link></li>
              <li><Link to="/formation">FORMATION</Link></li>
              <li><Link to="/consulting">CONSULTING</Link></li>
              <li><Link to="/contact">CONTACT</Link></li>
              <li className='icon'>
                <SearchIcon className='HeaderIcon' />
                <WorkIcon className='HeaderIcon' />
                <GridViewIcon className='HeaderIcon' />

              </li>
            </ul>
            <div>
              <button className='navbar-items-icon' onClick={() => setSidebar(!sidebar)}>
                {sidebar ? <CloseIcon /> : <MenuIcon />}
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
