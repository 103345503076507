import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../clients/partenerCarousel.css";
import "../../../src/App.css";
// import Slider from "react-slick";
import { partners } from "../../Exports/Partners";

function PartnersCarousel() {
  const Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider">
      <div className="partner_title">
        <h3 className="heading">Nos Partenaires</h3>
        <span>Ils nous ont fait confiance</span>
      </div>
      <Slider {...Settings}>
        {partners.map((partner, index) => (
          <div className="slide_cards" key={index}>
            <img src={partner.logo} alt={partner.name} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default PartnersCarousel;
