import React from 'react';
import About from '../components/about/About';
import GoalTeam from '../components/goalTeam/goalTeam';

const AboutPage = () => {
  return (
    <>
      < About />
      < GoalTeam />
    </>
  );
}

export default AboutPage;
