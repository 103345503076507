import React from 'react';
import Card from "react-bootstrap/Card";
import img1 from "../../Images/goal-one.jpg";
import img2 from "../../Images/goal-two.jpg";
import '../../components/goalTeam/goalTeam.css';

const GoalTeam = () => {
  return (
    <div className='goal'>
      <div className="abouting">
        <h1>Notre Mission & Valeur</h1>
      </div>
      <div className="about-container">
        <section className="about">
          <div className="about-image">
            <img src={img1} alt="" />
          </div>
          <div className="about-content">
            <h2>African Project Management Company</h2>
            <p>
              Notre mission est d’aligner la capacité professionnelle des Hommes
              ainsi que des organisations aux standards internationaux en
              management de projet. Avec passion, responsabilité mais surtout
              une culture d’entreprise vivante, nous apportons un accompagnement
              efficace, une véritable valeur ajoutée et assurons la bonne
              gouvernance de vos projets.
            </p>
            <a href="/contact" className="btn"> Contactez-nous</a>
          </div>
        </section>
      </div>

      <div className="abouting">
        <h1>Notre équipe</h1>
      </div>
      <div className="about-container">
        <section className="about">
          <div className="about-image">
            <img src={img2} alt="" />
          </div>
          <div className="about-content">
            <p>
              Notre équipe est constituée de consultants certifiés
              pluridisciplinaires et est en mesure d’apporter à la fois
              l’expertise technique pointue requise mais également la vision
              stratégique nécessaire à la conduite de vos projets.
            </p>
            <a href="/contact" className="btn"> Contactez-nous</a>
          </div>
        </section>
      </div>
    </div>
  );
}

export default GoalTeam;
