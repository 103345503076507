import React from 'react';
import '../objectifs/Objectif.css';
import '../../../src/App.css';
import img from "../../Images/valeuretobjectif.jpg";

const Objectif = () => {
  return (
    <div className="container-objectif">
      <h1 className='heading'>Nos valeurs et objectifs</h1>
      <div className="heading_underline"></div>
      <div className='about-content'>
        <div className='image'>
          <img src={img} alt="" />
        </div>
        <div className='content'>
          <p>Avec passion, responsabilité mais surtout une culture d’entreprise vivante,
            nous apportons un accompagnement efficace, une véritable valeur ajoutée
            et assurons la bonne gouvernance de vos projets. </p>
          <a className="btn" href="/apropos">En Savoir Plus</a>
        </div>
      </div>

    </div>
  );
}

export default Objectif;
