import React from 'react';
import '../footer/Footer.css';
const Footer = () => {
  return (
    <div>
      <footer>
        <div className="global-footer">
          <div className="footer-content">
            <h3>Contactez-nous</h3>
            <p>Email:contact@africanpmc.com</p>
            <p>T: +225 0767455576</p>
            <p>Cocody Attoban</p>
            <p>Abidjan, Côte d'Ivoire</p>
          </div>
          <div className="footer-content">
            <h3>Liens Rapides</h3>
            <ul className="list">
              <li><a href="/">Accueil</a></li>
              <li><a href="/apropos">À propos</a></li>
              <li><a href="/formation">Formations</a></li>
              <li><a href="/consulting">Consulting</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
          <div className="footer-content">
            <h3>Nous suivre</h3>
            <ul className='social-icons'>
            <li><a href=""><i class="fa-brands fa-facebook"></i></a></li>
            <li><a href=""><i class="fa-brands fa-instagram"></i></a></li>
            <li><a href=""><i class="fa-brands fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
        <div
          style={{
            borderTop: "2px solid red",
            marginTop: "2rem",
            display: "flex",
            justifyContent: "center",
            paddingTop: "1rem",
          }}
        >
          <span>
            2023 © Tous droit réservés – Designed by MS{" "}
            <span className="codeur">
              <a href="#">KBAVdev</a>{" "}
            </span>
          </span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
