import React from 'react';
import img from '../../Images/apropos-one.jpg';
import "../../components/about/About.css";

const About = () => {
  return (
    <div>
      <div className="abouting">
        <h1>A propos de nous</h1>
        <p>AFRICAN PROJECT MANAGEMENT COMPANY (APMC) est un cabinet de conseil et d’accompagnement spécialisé en management de projet et répond à l'ensemble des besoins en formation, conseil, accompagnement, système d’information projet et outils de planification en vue d'accroître la performance des entreprises.</p>
      </div>
      <div className="about-container">
        <section className="about">
          <div className="about-image">
            <img src={img} alt="" />
          </div>
          <div className="about-content">
            <h2>African Project Management Company</h2>
            <p>Nous alignons les entreprises aux standards internationaux en management de projets pour une meilleure atteinte de leurs objectifs stratégiques. Par ailleurs, depuis plus de 15 ans, nos experts forment les étudiants et cadres d’Afrique francophone. Nous les accompagnons à l’obtention de certifications internationales en management de projet et à la mise en place d’une structure en conduite de projet (PMO) dans les entreprises.</p>
            <p> Nous offrons à ceux qui nous font confiance les meilleures
              formations en matière de Management de projet. <br></br>
              <ul>
                <li> Formation en Certification PMP </li>
                <li>Formation en Certification CAPM</li>
                <li> Formation en Certification PSM I</li>
                <li> Formation en Certification PSPO I</li>
                <li> Formation en Certification PMI – RMP</li>
                <li> Des Formations non certifiantes </li>
              </ul>
              Notre approche globale s'adresse aussi aux entreprises désireuses
              d'avoir de meilleurs résultats en matière de gestion de projet.</p>
              <a href="/contact" className="btn"> Contactez-nous</a>

          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
